import {BaseRequest} from "@/request/BaseRequest";

class Merchants extends BaseRequest {

    public requestPath: string = '/plat/merchants';

    /**
     * 获取商户列表，用于下拉
     * @param searchStr
     */
    getSummaryList(searchStr: string): any {
        return this.post(`${this.requestPath}/summaryList`, {searchStr: searchStr})
    }

}

const c = new Merchants();
export {c as Merchants}