






































































































































import {Vue, Component} from "vue-property-decorator";
import {Merchants} from "@/request/plat/Merchants";
import {Common} from "@/request/Common";

@Component({
    name: 'plat_merchants'
})
export default class plat_merchants extends Vue {
    modal: any = Merchants;
    provinceList: Array<any> = [];
    cityList: Array<any> = [];
    search: Array<any> = [
        {
            type: 'Input',
            label: '公司名称',
            key: 'companyName',
            width: '160px',
            clearable: true,
            placeholder: '请填写公司名称'
        },
        {
            type: 'Input',
            label: '负责人姓名',
            key: 'masterName',
            width: '160px',
            clearable: true,
            placeholder: '请填写负责人姓名'
        },
        {
            type: 'Input',
            label: '负责人电话',
            key: 'masterPhone',
            width: '160px',
            clearable: true,
            placeholder: '请输入负责人电话'
        }
    ];
    formData: any = {
        corporationId: '',
        companyName: '',
        phone: '',
        mpAccount: '',
        mpId: '', //AppId
        mpPassword: '',
        mpSecret: '',
        provinceCode: '',
        cityCode: '',
        masterName: '',
        masterPhone: '',
        license: '',
        cardFront: '',
        cardBack: '',
        others: []
    };
    formRules: any = {
        name: {required: true, message: '请填写名称', trigger: 'blur'},
    };
    columns: Array<any> = [
        {
            title: '公司名字',
            key: 'companyName',
            minWidth: 180
        },
        {
            title: '联系人名字',
            key: 'masterName',
            minWidth: 120
        },
        {
            title: '联系人电话',
            key: 'masterPhone',
            minWidth: 140
        },
        {
            title: '公众号AppId',
            key: 'mpId',
            minWidth: 180
        },
        {
            title: '公众号secret',
            key: 'mpSecret',
            minWidth: 160
        },
        {
            title: '公众号账号',
            key: 'mpAccount',
            minWidth: 180
        },
        {
            title: '公众号密码',
            key: 'mpPassword',
            minWidth: 180
        },
        {
            title: '省市',
            minWidth: 180,
            render: (h: any, p: any) => h('p', `${p.row.province} ${p.row.city}`)
        }
    ];
    created() {
        Common.provinceList().then((body: any) => {
            this.provinceList = body.data;
        });
    }
    createNew() {
        delete this.formData.id;
    }
    edit(row: any) {
        this.formData = row;
    }
    submit() {
        let self: any = this;
        let params = {...self.formData};
        self.$refs['el-form'].validate((valid: boolean) => {
            if (valid) {
                self.$refs['el-table'].doSubmit(params).then(() => {

                });
            }
        })
    }
    provinceChange(id: string) {
        Common.cityList(id).then((body: any) => {
            this.cityList = body.data;
        })
    }
}
